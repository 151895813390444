import React, { useState, useEffect, useContext } from 'react';
import BASEURL from '../context/ApiEndpoints';
import AuthContext from '../context/AuthContext';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { useParams } from 'react-router-dom';

const CreateStatement = ({ initialData = null, onSave }) => {
    const { authTokens, user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const [tags, setTags] = useState([]);
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (initialData) {
            setTags(initialData.tags || []);
        }
    }, [initialData]);

    const handleTagDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTagAddition = (tag) => {
        setTags([...tags, tag.text]);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Set the base64 string
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setApiError(null);
        setFieldErrors({});
    
        try {
            const jsonData = {
                description: e.target.description.value,
                tags: tags.length > 0 ? tags : "", // Use an empty string if no tags
                image: image || initialData?.image, // Send the base64 image if present
            };
    
            const url = initialData
                ? `${BASEURL}content/statement/${initialData.id}/`
                : `${BASEURL}content/statement/`;
            const method = initialData ? 'PUT' : 'POST';
    
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json', // Ensure JSON content type
                    'Authorization': 'Bearer ' + authTokens?.access,
                },
                body: JSON.stringify(jsonData), // Send the data as JSON
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.detail) {
                    throw new Error(errorData.detail);
                } else {
                    setFieldErrors(errorData);
                }
                throw new Error(`API request failed with status ${response.status}`);
            }
    
            const responseData = await response.json();
            console.log('Posted data:', responseData);
            navigate(`/${user.username}`); // Navigate after successful submission
        } catch (error) {
            setApiError(error);
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {isLoading && <Typography color="text.secondary">Loading...</Typography>}
            {apiError && <Typography color="error">Error: {apiError.message}</Typography>}

            <Box
                sx={{
                    maxWidth: { xs: '100%', sm: '500px' },
                    width: '100%',
                    padding: { xs: 3, sm: 5 },
                    borderRadius: 3, // Smoother border radius
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                {/* Close (X) Button */}
                <IconButton
                    onClick={() => navigate(`/${user.username}`)}
                    sx={{ position: 'absolute', top: 16, right: 16, color: 'text.primary' }}
                >
                    <CloseIcon />
                </IconButton>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2, // Adds space between form elements
                    }}
                >
                    <TextField
                        margin="normal"
                        fullWidth
                        name="description"
                        label="Description"
                        type="text"
                        id="description"
                        defaultValue={initialData ? initialData.description : ''}
                        error={Boolean(fieldErrors.description)}
                        helperText={fieldErrors.description}
                        variant="outlined"
                        InputProps={{
                            sx: { color: 'text.primary' },
                        }}
                        sx={{ bgcolor: 'background.paper' }}
                    />

                    <ReactTags
                        tags={tags.map((tag, index) => ({ id: tag, text: tag }))}
                        handleDelete={handleTagDelete}
                        handleAddition={handleTagAddition}
                        placeholder="Add tags (optional)"
                    />
                    {fieldErrors.tags && <Typography color="error">{fieldErrors.tags}</Typography>}

                    <input
                        type="file"
                        accept="image/jpeg,image/png,image/gif"
                        name="image"
                        id="image"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                    <label htmlFor="image">
                        <Button component="span" variant="contained" color="primary" sx={{ mt: 2 }}>
                            Upload Image
                        </Button>
                    </label>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {initialData ? 'Save Changes' : 'Post'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default CreateStatement;
