import React, { useState, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Box, Toolbar, IconButton, Typography, MenuItem, Menu } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [addMenuAnchor, setAddMenuAnchor] = useState(null); // State for AddIcon menu
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isAddMenuOpen = Boolean(addMenuAnchor); // Check if AddIcon menu is open
  const { user, logoutUser } = useContext(AuthContext);
  const inputRef = useRef(null);

  const theme = useTheme(); // Using the custom theme

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddMenuOpen = (event) => {
    setAddMenuAnchor(event.currentTarget);
  };

  const handleAddMenuClose = () => {
    setAddMenuAnchor(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user ? (
        [
          <MenuItem key="my-account" onClick={() => { handleMenuClose(); navigate(`/profile/${user.username}`); }}>Settings</MenuItem>,
          <MenuItem key="explorer" onClick={() => { handleMenuClose(); navigate('/explorer'); }}>Explorer</MenuItem>,
          <MenuItem key="feed" onClick={() => { handleMenuClose(); navigate('/feed'); }}>Feed</MenuItem>,
          <MenuItem key="logout" onClick={() => { logoutUser(); navigate('/login'); }}>Logout</MenuItem>
        ]
      ) : (
        [
          <MenuItem key="login" onClick={() => { handleMenuClose(); navigate('/login'); }}>Login</MenuItem>,
          <MenuItem key="register" onClick={() => { handleMenuClose(); navigate('/register'); }}>Register</MenuItem>,
          <MenuItem key="explorer" onClick={() => { handleMenuClose(); navigate('/explorer'); }}>Explorer</MenuItem>
        ]
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        borderRadius: '0px',
      }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>

          {/* Logo */}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{ 
              display: { xs: 'block', sm: 'block' }, 
              fontWeight: 'bold',
              color: theme.palette.primary.main,
              textDecoration: 'none'
            }}
          >
            UDots
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {/* Search and Profile Menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchIcon
              onClick={() => { navigate('/search'); }}
              sx={{ cursor: 'pointer', marginRight: 2, color: theme.palette.text.primary }}
            />

            {user ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {/* AddIcon with menu */}
                <AddIcon 
                  onClick={handleAddMenuOpen}
                  sx={{ cursor: 'pointer', color: theme.palette.text.primary }}
                />
                <Menu
                  anchorEl={addMenuAnchor}
                  open={isAddMenuOpen}
                  onClose={handleAddMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate('/app/createlink');
                      handleAddMenuClose();
                    }}
                  >
                    Create Link
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate('/app/createstatement');
                      handleAddMenuClose();
                    }}
                  >
                    Create Statement
                  </MenuItem>
                </Menu>

                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  onClick={() => { navigate(`/${user.username}`); }}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <MoreVertIcon 
                  onClick={handleProfileMenuOpen} 
                  aria-controls={menuId} 
                  aria-haspopup="true" 
                  sx={{ cursor: 'pointer', color: theme.palette.text.primary }}
                />
              </Box>
            ) : (
              <>
                <MenuItem onClick={() => { navigate('/login'); }} sx={{ color: theme.palette.text.primary }}>
                  Login
                </MenuItem>
                <MenuItem onClick={() => { navigate('/register'); }} sx={{ color: theme.palette.text.primary }}>
                  Register
                </MenuItem>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
