import * as React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import FollowButton from './FollowButton';
import AuthContext from '../context/AuthContext';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const ProfileCard = ({ data }) => {
  const [isFollowing, setIsFollowing] = React.useState(data.is_following);
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleFollowToggle = (newStatus) => {
    setIsFollowing(newStatus);
  };

  return (
    <Card sx={{ width: '100%', margin: 'auto',backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: 3, position: 'relative', p: 2 }}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Badge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <Avatar sx={{ width: 100, height: 100 }} src={data.image}></Avatar>
          </Badge>
        </Grid>
        <Grid item>
          <Typography variant="h5" align="center">{data.user.username}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="text.secondary" align="center">{data.about_me}<br />{data.bio}</Typography>
        </Grid>

        <Grid item container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <Typography color="primary" variant="subtitle1" align="center">{data.follower_count} Follower</Typography>
          </Grid>
          <Grid item xs={6}>
            {data.user.username === user?.username ? (
              <Button onClick={() => navigate(`/profile/${user.username}`)} variant="outlined" color="primary" fullWidth>Edit Profile</Button>
            ) : (
              <FollowButton variant="outlined" color="primary" username={data.user.username} isFollowing={isFollowing} onFollowToggle={handleFollowToggle} fullWidth />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfileCard;
