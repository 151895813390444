import Explorerpage from "./Explorerpage";
import Search from "./Search";

const Explore = () => {
  return (
    <div>
      <Search />
      <Explorerpage />
    </div>
  );
}

export default Explore;